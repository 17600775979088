import React, { useState } from 'react';
import { Modal, Button, Textarea, Label } from 'flowbite-react';

interface ChatModalUserProps {
  feedbackid: string;
  firstname: string; 
  lastname: string;
  email_user: string; 
}

const ChatModalComment: React.FC<ChatModalUserProps> = ({ feedbackid, firstname, lastname, email_user }) => {
  const [comment, setComment] = useState('');

  const handleSave = () => {
    const token = localStorage.getItem('token'); // Token aus dem LocalStorage holen
    if (!token) {
      alert('Token nicht gefunden. Bitte erneut anmelden.');
      return;
    }

    const data = {
      kpi_feedback_id: feedbackid,
      email_user: email_user,
      value: comment,
      comment_type: 'nachkontakt'
    };

    

      fetch(`${process.env.REACT_APP_API_BASE_URL}/postcomments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + token // Token im Header mitgeben
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Erfolg:', data);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Fehler:', error);
        alert('Fehler beim Speichern des Kommentars.');
      });
    
  };

  return (
    <>
      <Modal.Header>Kommentar</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
            <>
              <div className="grid grid-flow-col justify-stretch space-x-4">
                <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="comment" value="Kommentar" />
                  </div>
                  <Textarea 
                    id="comment" 
                    placeholder=". . ." 
                    required 
                    rows={4} 
                    value={comment} 
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
              <Button className="border-2 dark:text-white" color="gray dark:text-white" onClick={handleSave}>
                Speichern
              </Button>
            </>
          
        </div>
      </Modal.Body>
    </>
  );
};

export default ChatModalComment;
