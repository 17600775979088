/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Label, TextInput } from "flowbite-react";
import type { FC } from "react";
import muuuhlogo from "../assets/muuuh_logo.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext.tsx'; 
import { useLocation } from "react-router-dom";

const LogInPage: FC = function () {
   

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>(''); // Zustand für die Fehlermeldung
  const auth = useAuth();
  const navigate = useNavigate();
  
  // Logo dynamisch je Mandant laden 
  let location = useLocation();
  let pathSegments = location.pathname.split('/')
  let mandant_seg = pathSegments[1]
  let logopath = process.env.PUBLIC_URL +'/'+ mandant_seg + '.svg'


  
  let pwrecoverypath = `/${mandant_seg}/passwortvergessen`


  //App Namen beziehen 
 
  const [appName, setAppName] = useState<string | null>(null);
  const [appColorOne, setAppColorOne] = useState<string | null>(null);

  useEffect(() => {
   
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getmandant/${mandant_seg}`)
        .then(response => response.json())
        .then(data => {
          setAppName(data[0].mandant_app_name);
          setAppColorOne(data[0].mandant_farbschema1);
          localStorage.setItem('appname', data[0].mandant_app_name)
          localStorage.setItem('appcolorone', data[0].mandant_farbschema1)
          setAppColorOne(localStorage.getItem('appcolorone'))
        })
        .catch(error => console.error('Fehler beim Abrufen der Mandanten Daten:', error));
      
  }, [mandant_seg]);


  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(''); // Setze den Fehlerzustand zurück, bevor ein neuer Login-Versuch unternommen wird

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/login`;

    try {
      console.log(apiUrl)
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        // Wenn der Login fehlschlägt, setze eine entsprechende Fehlermeldung
        setError('Login fehlgeschlagen. Bitte überprüfe deine Eingaben.');
        return;
      }

      const data = await response.json();
      auth.login(data.token, data.mandant); // Aktualisiere den Authentifizierungsstatus und speichere den Token
      const mandant_local = localStorage.getItem('mandant')
      //Farbcodes laden 

      navigate(`/${mandant_local}/dashboard_neu`); 
    } catch (error) {
      console.error('Ein Fehler ist aufgetreten:', error);
      setError('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };
 
  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <div className="flex items-center gap-x-1">
            <img
            alt="Muuuh Group Logo"
            src={muuuhlogo}
            className="w-1/4"
            />
        </div>
      <Card
        className="w-full md:max-w-screen-sm [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
      >
        <img
            alt="Logo"
            src={logopath}
            className="w-32 h-32 md:w-32 md:h-32 lg:w-32 lg:h-32 object-contain"
            />
        <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
          {appName}
        </h1>
        <form onSubmit={handleLogin}>
        <div className="mb-4 flex flex-col gap-y-3">
            <Label htmlFor="email">E-Mail</Label>
            <TextInput
              id="email"
              name="email"
              placeholder="name@email.de"
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6 flex flex-col gap-y-3">
            <Label htmlFor="password">Passwort</Label>
            <TextInput
              id="password"
              name="password"
              placeholder="••••••••"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-6 flex items-center justify-between">
            <div className="flex items-center gap-x-3">
            </div>
            <a
              href={pwrecoverypath}
              className="w-1/2 text-right text-sm text-primary-600 dark:text-primary-300"
            >
              Passwort vergessen?
            </a>
          </div>
          {error && <div className="mb-4 text-sm text-red-500">{error}</div>}
          <div className="mb-6">
            <Button type="submit" className={'bg-'+ appColorOne +' hover:bg-gray-500 w-full lg:w-auto'}>
              Einloggen
            </Button>
          </div>
          <p className="text-sm text-gray-500 dark:text-gray-300">
            Noch nicht registriert?&nbsp;
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-300">
            Sie erhalten eine Einladungsmail, sobald Daten für Sie verfügbar sind.
          </p>
        </form>
      </Card>
    </div>
  );
};

export default LogInPage;