import React, { useEffect, useState } from 'react';
import type { FC } from "react";
import { DarkThemeToggle, Navbar, Avatar, Dropdown, Button } from "flowbite-react";
import { jwtDecode } from "jwt-decode";
import { useAuth } from '../AuthContext.tsx';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const KPINavbar: FC = function () {
  const [appname, setAppName] = useState<string | null>(null);
  const [appcolorone, setAppColorOne] = useState<string | null>(null);

  interface DecodedToken {
    username: string;
    role: 'user' | 'supervisor' | 'superadmin';
  }
  const navigate = useNavigate();
  const { logout } = useAuth(); 
  const [username, setUsername] = useState(''); 
  const [role, setRole] = useState(''); 
  const [buttonText, setButtonText] = useState('Berater-Ansicht'); 
  const [urlChangeView, setUrlChangeView] = useState(() => {
    // Sicherstellen, dass nicht null link erzeugt wird
    const savedValue = localStorage.getItem('urlChangeView');
    return savedValue !== null ? savedValue : '';
  });

  const mandant_local = localStorage.getItem('mandant')
  const location = useLocation(); 
  const isActive = (path: string) => location.pathname === path;

  useEffect(() => {
    localStorage.setItem('urlChangeView', urlChangeView);
  }, [urlChangeView]);

  useEffect(() => {
    
    // Decodiere den JWT Token
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token) as DecodedToken;
      setUsername(decoded.username);
      setRole(decoded.role); 
    }
    setAppName(localStorage.getItem('appname')); 
    setAppColorOne(localStorage.getItem('appcolorone'));
    if(role === 'user'){
      setUrlChangeView('');
      localStorage.setItem('urlChangeView', '');
    }


    // Button Text abhängig von aktueller URL
    if (location.pathname.includes('_view_user')){
      setButtonText('Führungskräfte-Ansicht'); 
      localStorage.setItem('buttonText', 'Führungskräfte-Ansicht');
    } else {
      setButtonText('Berater-Ansicht')
      localStorage.setItem('buttonText', 'Berater-Ansicht');
    }; 

  }, [location.pathname, role]);

  const handleLogout = () => {
    logout();
  }

  

  const handleButtonClick = () => {
    if (buttonText === 'Führungskräfte-Ansicht') {
      setButtonText('Berater-Ansicht');
      setUrlChangeView('');
      localStorage.setItem('buttonText', 'Berater-Ansicht');
      localStorage.setItem('urlChangeView', '');
      navigate('/' + mandant_local + '/dashboard_neu');
      
    } else {
      setButtonText('Führungskräfte-Ansicht');
      setUrlChangeView('_view_user');
      localStorage.setItem('buttonText', 'Führungskräfte-Ansicht');
      localStorage.setItem('urlChangeView', '_view_user');
      navigate('/' + mandant_local + '/dashboard_neu_view_user');
    }
    // Seite neu laden
    //window.location.reload();
  }

  

  let logopath = process.env.PUBLIC_URL + '/' + mandant_local + '.svg';

  return (
    <div className="sticky top-0 z-50">
      <Navbar fluid>
        <div className="w-full p-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-6">
              <Navbar.Brand href={'/' + mandant_local + '/dashboard_neu'} >
                <img alt="Logo" src={logopath} className="mr-5 h-6 sm:h-8" />
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                  {appname}
                </span>
              </Navbar.Brand>
              <div>
                <Navbar.Collapse>
                  <Navbar.Link href={'/' + mandant_local + '/dashboard_neu' + urlChangeView} className={isActive('/' + mandant_local + '/dashboard_neu' + urlChangeView) ? 'text-' + appcolorone : undefined}>
                    Aktiv
                  </Navbar.Link>
                  <Navbar.Link href={'/' + mandant_local + '/dashboard_wiedervorlage' + urlChangeView} className={isActive('/' + mandant_local + '/dashboard_wiedervorlage' + urlChangeView) ? 'text-' + appcolorone : undefined}>
                    Wiedervorlage
                  </Navbar.Link>
                  <Navbar.Link href={'/' + mandant_local + '/dashboard_nachkontakt' + urlChangeView} className={isActive('/' + mandant_local + '/dashboard_nachkontakt' + urlChangeView) ? 'text-' + appcolorone : undefined}>
                    Nachkontakt
                  </Navbar.Link>
                  <Navbar.Link href={'/' + mandant_local + '/dashboard_archiv' + urlChangeView} className={isActive('/' + mandant_local + '/dashboard_archiv' + urlChangeView) ? 'text-' + appcolorone : undefined}>
                    Archiv
                  </Navbar.Link>
                </Navbar.Collapse>
              </div>
            </div>
            <div className="flex items-center gap-3">
              {(role === 'supervisor' || role === 'superadmin') && (
                <Button size='sm' onClick={handleButtonClick}>
                  Wechsel zur {buttonText}
                </Button>
              )}
              <DarkThemeToggle />
              <div className="flex md:order-2">
                <Dropdown
                  arrowIcon={false}
                  inline
                  label={<Avatar alt="User settings" />}
                >
                  <Dropdown.Header>
                    <span className="block truncate text-sm font-medium">{username}</span>
                  </Dropdown.Header>
                  <Dropdown.Item href={'/' + mandant_local + '/changepw'}>
                    Passwort zurücksetzen
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout}>
                    Ausloggen
                  </Dropdown.Item>
                </Dropdown>
                <Navbar.Toggle />
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      <div className="w-full border-b-2 border-gray-200 dark:border-gray-700"></div>
    </div>
  );
};

export default KPINavbar;
