import type { FC } from "react";
import React, { useEffect, useState } from 'react';
import NpsScale from "./nps_scale.tsx";
import { Modal, Button, Accordion } from 'flowbite-react';
import ChatModalComment from "./chat_comment.tsx";
// KPI Feedback Component User 
// Nimmt Feedback Data entgegen und stellt diese dar

// Infos zur Darstellung 
interface FeedbackData {
    email_supervisor: string;
    email_user: string;
    firstname_user: string; 
    lastname_user: string; 
    kpi_date: string; 
    kpi_feedback_id: string;
    kpi_text: string;
    kpi_time: string; 
    kpi_value: number; 
    custom1: string; 
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    custom6: string;
    custom7: string;
    custom8: string;
    custom9: string;
    custom10: string;
    custom11: string;
    custom12: string;
    custom13: string;
    custom14: string;
    custom15: string;
    custom16: string;
    custom17: string;
    custom18: string;
    custom19: string;
    custom20: string;
    custom21: string;
    custom22: string;
    custom23: string;
    custom24: string;
    custom25: string;
    custom26: string;
    custom27: string;
    custom28: string;
    custom29: string;
    custom30: string;
    custom31: string;
    custom32: string;
    custom33: string;
    custom34: string;
    custom35: string;
    custom36: string;
    custom37: string;
    custom38: string;
    custom39: string;
    custom40: string;
    mandant: string;
    survey1: string;
    survey2: string;
    survey3: string;
    survey4: string;
    survey5: string;
    survey6: string;
    survey7: string;
    survey8: string;
    survey9: string;
    survey10: string;
    survey11: string;
    survey12: string;
    survey13: string;
    survey14: string;
    survey15: string;
    survey16: string;
    survey17: string;
    survey18: string;
    survey19: string;
    survey20: string;
    survey21: string;
    survey22: string;
    survey23: string;
    survey24: string;
    survey25: string;
    survey26: string;
    survey27: string;
    survey28: string;
    survey29: string;
    survey30: string;
    survey31: string;
}

interface KPIUserProps {
    feedbackData: FeedbackData;
    archiv: boolean; 
}

interface CommentData {
    email_user: string; 
    timestamp: string; 
    value: string; 
    kpi_feedback_id: string; 
    comment_id: string;
    commen_type: string; 
    date: string;
}

const KPIUserNachkontakt: FC<KPIUserProps> = ({ feedbackData, archiv }) => {

  
  const [commentData, setCommentData] = useState<CommentData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  
  

  useEffect(() => {
    // Decodiere den JWT Token
    const token = localStorage.getItem('token');
    if (token) {
      
      // Get Request an die API
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getcomments/nachkontakt/${feedbackData.kpi_feedback_id}`, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        })
        .then(response => response.json())
        .then(data => {
          setCommentData(data);
        })
        .catch(error => console.error('Fehler beim Abrufen der Comment Daten:', error));
    }
  }, [feedbackData.kpi_feedback_id]);


      


    return (
      <div className="relative rounded-lg bg-white dark:bg-gray-800 sm:p-4 w-full border-2">
         {feedbackData.survey4 === 'Ja' &&   
                 <div className="relative top-0 left-0">            
                 <div className="flex items-center justify-between">
                     <div className="shrink-0">
                     <p
                     className="p-1 dark:text-white inline-flex items-center rounded-lg text-xs font-small text-primary-700 bg-red-200 dark:text-primary-500 sm:text-xs"
                     >
                     Beratungsbedarf
                     </p>
                     
                     </div>
                 </div>
                 </div>
            }
         <Modal size="xl" show={openModal} onClose={() => setOpenModal(false)}>
            <ChatModalComment feedbackid={feedbackData.kpi_feedback_id} firstname={feedbackData.custom18} lastname={feedbackData.custom17} email_user={feedbackData.email_user}/>
                <Modal.Footer className="relative">
                    <div className="mt-2 mb-2"></div>
                    <Button className="absolute right-5 border-2 dark:text-white" color="gray dark:text-white" onClick={() => setOpenModal(false)}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal> 
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
            <div>
                            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Gesprächspartner</p>
                                <div className="mb-4 flex items-center justify-between">
                                    <p className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                                        {feedbackData.custom3 && feedbackData.custom3.trim().length >= 2 
                                            ? feedbackData.custom3 
                                            : (
                                                <>
                                                    {feedbackData.custom18 && feedbackData.custom18.trim().length > 0 && feedbackData.custom18}
                                                    {feedbackData.custom18 && feedbackData.custom18.trim().length > 0 && feedbackData.custom17 && feedbackData.custom17.trim().length > 0 && ' '}
                                                    {feedbackData.custom17 && feedbackData.custom17.trim().length > 0 && feedbackData.custom17}
                                                </>
                                            )}
                                    </p>
                                </div>

            </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="items-center">
                <NpsScale score={Number(feedbackData.kpi_value)}/>
              </div>
            </li>
            <li>
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Interviewdatum</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base  leading-none text-gray-900 dark:text-white">
                    {feedbackData.kpi_date + ' - ' + feedbackData.kpi_time}
                </p>
            </div>
            </li>
            <li>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Fälligkeitsdatum</p>
                <div className="mb-4 flex items-center justify-between">
                    <p className="text-base leading-none text-gray-900 dark:text-white">
                        {feedbackData.custom40}
                    </p>
                </div>
            </li>
            <li>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Telefon Kunde</p>
                <div className="mb-4 items-center justify-between">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom25}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom26}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom27}
                        </p>
                </div>
            </li>
            <li>
            <p className="text-xs font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">Kommentare</p>
            <div className={`${Array.isArray(commentData) && commentData.length > 3 ? 'max-h-20 overflow-y-auto' : ''} mb-1`}>
            {Array.isArray(commentData) && commentData.map((comment, index) => (
                <div key={index} className="max-w-lg border-2 rounded-lg p-2 mb-1 mr-1">
                <p className="text-xs text-gray-500 dark:text-gray-400">
                    {comment.email_user}
                </p>
                <p className="text-xs text-gray-900 dark:text-white">
                    {comment.value}
                </p>
                </div>
            ))}
            </div>
            {archiv === false && 
            <div className="flex items-center justify-between mb-1 mt-2">
            <div className="shrink-0">
            <button
            className={'border-2 p-1 pl-2 dark:text-white inline-flex items-center rounded-xl text-xs font-medium uppercase text-white text-primary-700 bg-spkrot hover:bg-red-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-xs'}
            onClick={() =>setOpenModal(true)}
            >
            Kommentieren
            <svg
                className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
                />
            </svg>
            </button>
            </div>
            </div>
            }
            </li>
          </ul>
          <Accordion collapseAll >
            <Accordion.Panel>
                <Accordion.Title>Interview</Accordion.Title>
                <Accordion.Content>
                    <ul>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Begründung NPS</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.kpi_text}
                        </p>
                    </div>
                    {/*NEUE FELDER SPK LW - START*/}
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Verbesserung Promotoren</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey3}
                        </p>
                    </div>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Sparkasse der Zukunft</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey23}
                        </p>
                    </div>
                    {/*NEUE FELDER SPK LW - END*/}
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Beratungswunsch</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey4}
                        </p>
                    </div>

                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Thema oder Anliegen?</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey6}
                        </p>
                    </div>

                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Terminwunsch?</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey7}
                        </p>
                    </div>
                    </ul>
                </Accordion.Content>
            </Accordion.Panel>
            </Accordion>
            <Accordion collapseAll>
            <Accordion.Panel>
            <Accordion.Title>Kunde</Accordion.Title>
                <Accordion.Content>
                    <ul>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Personen Nr.</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom14}
                        </p>
                    </div>
                    
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Zielperson</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom18} {' '} {feedbackData.custom17}
                        </p>
                    </div>
                
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Telefon</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom25}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom26}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom27}
                        </p>
                    </div>
                
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Team</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom2} 
                        </p>
                    </div>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">ID</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.kpi_feedback_id}
                        </p>
                    </div>
                    </ul>
                </Accordion.Content>
            </Accordion.Panel>
            </Accordion>
        </div>
      </div>
    );
  };


  export default KPIUserNachkontakt;
