import type { FC } from "react";
import React, { useEffect, useState } from 'react';
import NpsScale from "./nps_scale.tsx";
import { Dropdown, Modal, Button, Accordion } from 'flowbite-react';
import ChatModalComment from "./chat_comment.tsx";
import PopupNachfass from "./popup_nachfass.tsx";
import { jwtDecode } from "jwt-decode";
// KPI Feedback Component User 
// Nimmt Feedback Data entgegen und stellt diese dar

// Infos zur Darstellung 
interface FeedbackData {
    email_supervisor: string;
    email_user: string;
    firstname_user: string; 
    lastname_user: string; 
    kpi_date: string; 
    kpi_feedback_id: string;
    kpi_text: string;
    kpi_time: string; 
    kpi_value: number; 
    custom1: string; 
    custom2: string;
    custom3: string;
    custom4: string;
    custom5: string;
    custom6: string;
    custom7: string;
    custom8: string;
    custom9: string;
    custom10: string;
    custom11: string;
    custom12: string;
    custom13: string;
    custom14: string;
    custom15: string;
    custom16: string;
    custom17: string;
    custom18: string;
    custom19: string;
    custom20: string;
    custom21: string;
    custom22: string;
    custom23: string;
    custom24: string;
    custom25: string;
    custom26: string;
    custom27: string;
    custom28: string;
    custom29: string;
    custom30: string;
    custom31: string;
    custom32: string;
    custom33: string;
    custom34: string;
    custom35: string;
    custom36: string;
    custom37: string;
    custom38: string;
    custom39: string;
    custom40: string;
    mandant: string;
    survey1: string;
    survey2: string;
    survey3: string;
    survey4: string;
    survey5: string;
    survey6: string;
    survey7: string;
    survey8: string;
    survey9: string;
    survey10: string;
    survey11: string;
    survey12: string;
    survey13: string;
    survey14: string;
    survey15: string;
    survey16: string;
    survey17: string;
    survey18: string;
    survey19: string;
    survey20: string;
    survey21: string;
    survey22: string;
    survey23: string;
    survey24: string;
    survey25: string;
    survey26: string;
    survey27: string;
    survey28: string;
    survey29: string;
    survey30: string;
    survey31: string;
}

interface KPIUserProps {
    feedbackData: FeedbackData;
    archiv: boolean; 
}

interface CommentData {
    email_user: string; 
    timestamp: string; 
    value: string; 
    kpi_feedback_id: string; 
    comment_id: string;
    commen_type: string; 
}

 
const KPISuperuserNachkontakt: FC<KPIUserProps> = ({ feedbackData, archiv }) => {

  
  const [commentData, setCommentData] = useState<CommentData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalNachfass, setOpenModalNachfass] = useState(false);

// Anpassung 03.07. Username als Kommentator -> Vertreter Vorgesetzte
  interface DecodedToken {
    username: string;
    role: 'user' | 'supervisor';
  }
  const [username, setUsername] = useState('');

  useEffect(() => {
    // Decodiere den JWT Token
    const token = localStorage.getItem('token');
    if (token) {
        const decoded = jwtDecode(token) as DecodedToken;
        setUsername(decoded.username);
        
      
      // Get Request an die API
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getcomments/nachkontakt/${feedbackData.kpi_feedback_id}`, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        })
        .then(response => response.json())
        .then(data => {
          setCommentData(data);
        })
        .catch(error => console.error('Fehler beim Abrufen der Comment Daten:', error));
    }
  }, [feedbackData.kpi_feedback_id]);


    //Feature Feedback an berater übergeben Dropdown START 
    const fetchUserEmails = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return [];
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getusernames/${feedbackData.mandant}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch user emails');
            }
    
            const data = await response.json();
            //console.log(data); 
            return data;
        } catch (error) {
            console.error('Error fetching user emails:', error);
            return [];
        }
    };


    const [userEmails, setUserEmails] = useState<{ email: string; user_firstname: string; user_lastname: string }[]>([]);
    const [selectedUser, setSelectedUser] = useState<{ email: string; user_firstname: string; user_lastname: string } | null>(null);
    const [openModalZuweisen, setOpenModalZuweisen] = useState(false);

    useEffect(() => {
        const fetchEmails = async () => {
            const emails = await fetchUserEmails();
            setUserEmails(emails);
        };
        fetchEmails();
    }, []);

    const handleUserSelect = (user: { email: string; user_firstname: string; user_lastname: string }) => {
        setSelectedUser(user);
        setOpenModalZuweisen(true);
    };

    const handleConfirm = async () => {
        if (!selectedUser) return;

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/putfeedbackberater/edited_kpi/${feedbackData.kpi_feedback_id}/${selectedUser.email}/${selectedUser.user_firstname}/${selectedUser.user_lastname}`,
                {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update feedback');
            }

            setOpenModal(false);
            window.location.reload();
        } catch (error) {
            console.error('Error updating feedback:', error);
        }
    };
    //Feature Feedback an berater übergeben Dropdown END
      


    return (
      <div className="relative rounded-lg bg-white dark:bg-gray-800 p-3 w-full border-2">
         <Modal size="xl" show={openModal} onClose={() => setOpenModal(false)}>
            <ChatModalComment feedbackid={feedbackData.kpi_feedback_id} firstname={feedbackData.custom18} lastname={feedbackData.custom17} email_user={username}/>
                <Modal.Footer className="relative">
                    <div className="mt-2 mb-2"></div>
                    <Button className="absolute right-5 border-2 dark:text-white" color="gray dark:text-white" onClick={() => setOpenModal(false)}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal> 
            <Modal size="xl" show={openModalNachfass} onClose={() => setOpenModalNachfass(false)}>
                <PopupNachfass feedbackid={feedbackData.kpi_feedback_id}/>
                <Modal.Footer className="relative">
                    <div className="mt-2 mb-2"></div>
                    <Button className="absolute right-5 border-2 dark:text-white" color="gray dark:text-white" onClick={() => setOpenModalNachfass(false)}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal> 
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            <li>
            <div>
            {((feedbackData.firstname_user && feedbackData.firstname_user !== "null") || (feedbackData.lastname_user && feedbackData.lastname_user !== "null")) ? (
                    <>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Kundenberater</p>
                    <div className="mb-2 flex items-center justify-between">
                        <p className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                        {feedbackData.firstname_user && feedbackData.firstname_user !== "null" ? <span>{feedbackData.firstname_user} </span> : null}
                        {feedbackData.lastname_user && feedbackData.lastname_user !== "null" ? <span>{feedbackData.lastname_user}</span> : null}
                        </p>
                    </div>
                    </>
                ) : (
                    feedbackData.email_user && feedbackData.email_user !== "null" && (
                    <>
                        <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Kundenberater</p>
                        <div className="mb-2 flex items-center justify-between">
                        <p className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                            {feedbackData.email_user.split('@')[0]}
                        </p>
                        </div>
                    </>
                    )
                )}

                
                <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Gesprächspartner</p>
                <div className="mb-4 flex items-center justify-between">
                    <p className="text-lg font-bold leading-none text-gray-900 dark:text-white">
                        {feedbackData.custom3 && feedbackData.custom3.trim().length >= 2 
                            ? feedbackData.custom3 
                            : (
                                <>
                                    {feedbackData.custom18 && feedbackData.custom18.trim().length > 0 && feedbackData.custom18}
                                    {feedbackData.custom18 && feedbackData.custom18.trim().length > 0 && feedbackData.custom17 && feedbackData.custom17.trim().length > 0 && ' '}
                                    {feedbackData.custom17 && feedbackData.custom17.trim().length > 0 && feedbackData.custom17}
                                </>
                            )}
                    </p>
                </div>


                    
            </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="items-center">
                <NpsScale score={Number(feedbackData.kpi_value)}/>
              </div>
            </li>
            <li>
            <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Interviewdatum</p>
            <div className="mb-4 flex items-center justify-between">
                <p className="text-base  leading-none text-gray-900 dark:text-white">
                    {feedbackData.kpi_date + ' - ' + feedbackData.kpi_time}
                </p>
            </div>
            </li>
            <li>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Fälligkeitsdatum</p>
                <div className="mb-4 flex items-center justify-between">
                    <p className="text-base leading-none text-gray-900 dark:text-white">
                        {feedbackData.custom40}
                    </p>
                </div>
            </li>
            <li>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Telefon Kunde</p>
                <div className="mb-4 items-center justify-between">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom25}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom26}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom27}
                        </p>
                </div>
            </li>
             {/*Feature Feedback an anderen Berater übergeben START*/}
             <li>
            <div className="mt-2 mb-2">
                <Dropdown label="An anderen Berater übergeben" dismissOnClick={false} size="xs">
                    <div className="h-40 overflow-auto">
                    {userEmails.map((user) => {
                        const username = user.email.split('@')[0];
                        return (
                            <Dropdown.Item key={user.email} onClick={() => handleUserSelect(user)}>
                                {username}
                            </Dropdown.Item>
                        );
                    })}
                    </div>
                </Dropdown>
                {/* Modal zur Bestätigung */}
                {openModalZuweisen && (
                    <Modal show={openModalZuweisen} onClose={() => setOpenModalZuweisen(false)}>
                        <Modal.Header>Feedback Übergabe bestätigen</Modal.Header>
                        <Modal.Body>
                            Möchten Sie das Feedback an {selectedUser?.user_firstname} {selectedUser?.user_lastname} - {selectedUser?.email}  übergeben?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleConfirm}>Bestätigen</Button>
                            <Button color="gray" onClick={() => setOpenModalZuweisen(false)}>Abbrechen</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
            </li>
            {/*Feature Feedback an anderen Berater übergeben END*/}
            <li>
            <p className="text-xs font-bold text-gray-500 dark:text-gray-400 mb-1 mt-1">Kommentare</p>
            <div className={`${Array.isArray(commentData) && commentData.length > 3 ? 'max-h-20 overflow-y-auto' : ''} mb-1`}>
            {Array.isArray(commentData) && commentData.map((comment, index) => (
                <div key={index} className="max-w-lg border-2 rounded-lg p-2 mb-1 mr-1">
                <p className="text-xs text-gray-500 dark:text-gray-400">
                    {comment.email_user}
                </p>
                <p className="text-xs text-gray-900 dark:text-white">
                    {comment.value}
                </p>
                </div>
            ))}
            </div>
            {archiv === false && 
            <div className="mt-3">
                <div className="flex items-center justify-between mb-2">
                    <div className="shrink-0">
                    <button
                    className="border-2 p-1 pl-2 dark:text-white inline-flex items-center rounded-xl text-xs font-medium uppercase text-white text-primary-700 bg-spkrot hover:bg-red-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-xs"
                    onClick={() =>setOpenModal(true)}
                    >
                    An Berater zurückgeben
                    <svg
                        className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                        />
                    </svg>
                    </button>
                    </div>
                </div>
                <div className="flex items-center justify-between mb-2 ">
                    <div className="shrink-0">
                    <button
                    className="border-2 p-1 pl-2 dark:text-white inline-flex items-center rounded-xl text-xs font-medium uppercase text-white text-primary-700 bg-green-600 hover:bg-green-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-xs"
                    onClick={() => setOpenModalNachfass(true)}
                    >
                    Nachfass bestätigen
                    <svg
                        className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                        />
                    </svg>
                    </button>
                    </div>
                </div>
            </div>
            }
            </li>
          </ul>
          <Accordion collapseAll >
            <Accordion.Panel>
                <Accordion.Title>Interview</Accordion.Title>
                <Accordion.Content>
                    <ul>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Begründung NPS</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.kpi_text}
                        </p>
                    </div>
                    {/*NEUE FELDER SPK LW - START*/}
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Verbesserung Promotoren</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey3}
                        </p>
                    </div>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Sparkasse der Zukunft</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey23}
                        </p>
                    </div>
                    {/*NEUE FELDER SPK LW - END*/}
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Beratungswunsch</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey4}
                        </p>
                    </div>

                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Thema oder Anliegen?</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey6}
                        </p>
                    </div>

                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Terminwunsch?</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base text-gray-900 dark:text-white">
                            {feedbackData.survey7}
                        </p>
                    </div>
                    </ul>
                </Accordion.Content>
            </Accordion.Panel>
            </Accordion>
            <Accordion collapseAll>
            <Accordion.Panel>
            <Accordion.Title>Kunde</Accordion.Title>
                <Accordion.Content>
                    <ul>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Personen Nr.</p>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom14}
                        </p>
                    </div>
                    
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Zielperson</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom18} {' '} {feedbackData.custom17}
                        </p>
                    </div>
                
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Telefon</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom25}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom26}
                        </p>
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom27}
                        </p>
                    </div>
                
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">Team</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.custom2} 
                        </p>
                    </div>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400 mb-1 mt-1">ID</p>
                    <div className="mb-4 items-center justify-between space-y-1">
                        <p className="text-base leading-none text-gray-900 dark:text-white">
                            {feedbackData.kpi_feedback_id}
                        </p>
                    </div>
                    </ul>
                </Accordion.Content>
            </Accordion.Panel>
            </Accordion>
        </div>
      </div>
    );
  };


  export default KPISuperuserNachkontakt;
