import React, { StrictMode, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Flowbite } from "flowbite-react";
import "./index.css";
import ProtectedRoute from './ProtectedRoute.tsx';
import { AuthProvider } from './AuthContext.tsx'; 

import LogInPage from "./pages/log-in.tsx";
import ChangePWPage from "./pages/change_pw.tsx";
import DashboardPage from "./pages/kpidashboard_neu.tsx";
import DashboardPageWiedervorlage from "./pages/kpidashboard_wiedervorlage.tsx";
import DashboardPageNachkontakt from "./pages/kpidashboard_nachkontakt.tsx";
import DashboardPageArchiv from "./pages/kpidashboard_archiv.tsx";
import PWRecoveryPage from "./pages/pw_recovery.tsx";
import RegisterPage from "./pages/register.tsx";
import DashboardPageNeuViewUser from "./pages/kpidashboard_neu_view_user.tsx";
import DashboardPageWiedervorlageViewUser from "./pages/kpidashboard_wiedervorlage_view_user.tsx";
import DashboardPageNachkontaktViewUser from "./pages/kpidashboard_nachkontakt_view_user.tsx";
import DashboardPageArchivViewUser from "./pages/kpidashboard_archiv_view_user.tsx";

interface Mandant {
  mandant_id: string;
  mandant_farbschema1: string;
  mandant_farbschema2: string;
  mandant_app_name: string | null;
}
// MandantenRoutes-Komponente

 
  const App = () => {
    const [mandanten, setMandanten] = useState<Mandant[]>([]);
  
    useEffect(() => {
      // Ersetze dies durch deinen tatsächlichen API-Call
      fetch(`${process.env.REACT_APP_API_BASE_URL}/getmandant/all`)
        .then(response => response.json())
        .then(data => setMandanten(data)); // Direktes Setzen des API-Responses
    }, []);
  
    return (
      <StrictMode>
        <Flowbite>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                {mandanten.map((mandant) => (
                  <React.Fragment key={mandant.mandant_id}>
                    <Route path={`/${mandant.mandant_id}/`} element={<LogInPage />} />
                    <Route path={`/${mandant.mandant_id}/login`} element={<LogInPage />} />
                    <Route path={`/${mandant.mandant_id}/passwortvergessen`} element={<PWRecoveryPage />} />
                    <Route path={`/${mandant.mandant_id}`} element={<ProtectedRoute />}>
                      <Route index element={<DashboardPage />} />
                      <Route path="changepw" element={<ChangePWPage />} />
                      <Route path="dashboard_neu" element={<DashboardPage />} />
                      <Route path="dashboard_wiedervorlage" element={<DashboardPageWiedervorlage />} />
                      <Route path="dashboard_nachkontakt" element={<DashboardPageNachkontakt />} />
                      <Route path="dashboard_archiv" element={<DashboardPageArchiv />} />
                      <Route path="dashboard_neu_view_user" element={<DashboardPageNeuViewUser />} />
                      <Route path="dashboard_wiedervorlage_view_user" element={<DashboardPageWiedervorlageViewUser />} />
                      <Route path="dashboard_nachkontakt_view_user" element={<DashboardPageNachkontaktViewUser />} />
                      <Route path="dashboard_archiv_view_user" element={<DashboardPageArchivViewUser />} />
                      <Route path="register" element={<RegisterPage />}/>
                    </Route>
                  </React.Fragment>
                ))}
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </Flowbite>
      </StrictMode>
    );
  };
  
  const container = document.getElementById("root");
  if (!container) {
    throw new Error("React root element doesn't exist!");
  }
  
  const root = createRoot(container);
  
  root.render(<App />);