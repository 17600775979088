/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Label, TextInput } from "flowbite-react";
import type { FC } from "react";
import muuuhlogo from "../assets/muuuh_logo.png";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const RegisterPage: FC = function () {
   

  const [email, setEmail] = useState<string>('');
  const mandant = localStorage.getItem('mandant'); 
  const usertype = 'user'; 
  const [error, setError] = useState<string>(''); 
  const [success, setSuccess] = useState<string>(''); 
  
  
  
  // Logo dynamisch je Mandant laden
  let location = useLocation();
  let pathSegments = location.pathname.split('/')
  let mandant_seg = pathSegments[1]
  let logopath = process.env.PUBLIC_URL +'/'+ mandant_seg + '.svg'



  //App Namen beziehen 
 
  const [appName, setAppName] = useState<string | null>(null);
  const [appColorOne, setAppColorOne] = useState<string | null>(null);

  useEffect(() => {
   
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getmandant/${mandant_seg}`)
        .then(response => response.json())
        .then(data => {
          setAppName(data[0].mandant_app_name);
          setAppColorOne(data[0].mandant_farbschema1);
          localStorage.setItem('appname', data[0].mandant_app_name)
          localStorage.setItem('appcolorone', data[0].mandant_farbschema1)
          setAppColorOne(localStorage.getItem('appcolorone'))
        })
        .catch(error => console.error('Fehler beim Abrufen der Mandanten Daten:', error));
      
  }, [mandant_seg]);


  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(''); // Setze den Fehlerzustand zurück, bevor ein neuer Login-Versuch unternommen wird

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/register`;
    
    try {
      console.log(apiUrl)
      const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return;
        }
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mandant, email, usertype }),
      });
      
      if (response.status === 409) {
        // Wenn der Login fehlschlägt, setze eine entsprechende Fehlermeldung
        setError('Nutzer existiert bereits.');
        return;
      } else {
        setSuccess('Registrierung erfolgreich. Einladung versandt.')
      }
      const data = await response.json();
      console.log(data);

    } catch (error) {
      console.error('Ein Fehler ist aufgetreten:', error);
      setError('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };
 
  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <div className="flex items-center gap-x-1">
            <img
            alt="Muuuh Group Logo"
            src={muuuhlogo}
            className="w-1/4"
            />
        </div>
      <Card
        className="w-full md:max-w-screen-sm [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
      >
        <img
            alt="Logo"
            src={logopath}
            className="w-32 h-32 md:w-32 md:h-32 lg:w-32 lg:h-32 object-contain"
            />
        <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
          {appName}
        </h1>
        <p className="mb-3 text-xl font-bold dark:text-white md:text-xl">
          Nutzer Registrierung
        </p>
        <form onSubmit={handleRegister}>
        <div className="mb-4 flex flex-col gap-y-3">
            <Label htmlFor="email">E-Mail</Label>
            <TextInput
              id="email"
              name="email"
              placeholder="name@email.de"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {error && <div className="mb-4 text-sm text-red-500">{error}</div>}
          <p className="mt-2 mb-2">
          Klicken Sie auf „Registrieren“, um dem Benutzer eine Einladungsmail zu senden.
          </p>
          <div className="mb-6">
            <Button type="submit" className={'bg-'+ appColorOne +' hover:bg-gray-500 w-full lg:w-auto'}>
              Registrieren
            </Button>
          </div>
          {success && <div className="mb-2 text-sm text-green-500">{success}</div>}
          <p className="text-sm text-gray-500 dark:text-gray-300">
            Alle Nutzer werden automatisiert registriert und erhalten eine Einladungsmail, sobald Daten für Sie verfügbar sind.
          </p>
        </form>
      </Card>
    </div>
  );
};

export default RegisterPage;